import { subscribeToLiveData, requestLiveData } from "helpers/sockets";
import { useEffect, useState } from "react";

const useLiveData = (props) => {
    const [liveData, setLiveData] = useState(null);
    useEffect(() => {
        subscribeToLiveData((err, response) => {
            if (err) return;
            setLiveData(response);
        });
    }, []);
    return liveData;
};

export default useLiveData;
