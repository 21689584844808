import { getItem, updateObject } from "helpers/miscellaneous";
import { SET_AUTH_DATA, LOGOUT_USER, VERIFIED_USER } from "store/types/AuthData";
import { logOut } from '../../helpers/auth'
const initialState = {
    authData: getItem('auth') || null
}

const setAuthData = (state, action) => {
    return updateObject(state, {
        authData: action.authData
    });
}

const changeStatus = (state, action) => {
    const user = { ...state.authData.user, verified: action.payload.is_verified };
    const authData = { ...state.authData, user }
    console.log(user);
    return updateObject(state, {
        authData: authData
    });
}

const logOutUser = (state) => {
    logOut()
    return updateObject(state, {
        authData: initialState,
    });

}

const AuthData = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_DATA:
            return setAuthData(state, action);
        case LOGOUT_USER:
            return logOutUser(state)
        case VERIFIED_USER:
            return changeStatus(state, action);
        default:
            return state;
    }
};

export default AuthData;