import React from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.scss';
import { IonCol, IonGrid, IonRow } from '@ionic/react';

const Header = ({ data }) => (
  <div className={styles.Header}>
    <IonGrid fixed={true}>
      <IonRow>
        {
          data?.map((item, index) => (
            <IonCol key={index}>{item}</IonCol>
          ))
        }
      </IonRow>
    </IonGrid>
  </div>
);

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
