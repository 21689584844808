import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import OptionsAccordion from "../../components/OptionsAccordion/OptionsAccordion";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Footer from "components/Footer/Footer";
import * as Loader from 'react-loader-spinner'
import { setSelectedFarm } from "store/actions/AppData";
// import { setDevicesData } from "store/actions/allDevices";
import CustomCalendar from "components/Calendar/Calendar";
import { setDevicesBasedOnLocation } from "store/actions/AppData";
import ChartWrap from "components/ChartWrap/ChartWrap";
import { setSelectedDevice, setSelectedTime, setJumpTime, setJump } from "store/actions/AppData";
import { getShortDate } from "helpers/miscellaneous";
import { addMessageService } from "services/api/message";



const Home = () => {

  const { selectedDevices, selectedDevice, selectedTime, sensorsData, farms, selectedFarm, activeDevices, jumpTime } = useSelector(state => state.appData)
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();


  const [isVisible, setVisible] = useState(false)

  const [value, setValue] = useState([])
  const timeOptions = [
    { label: "Live(Last 15min)", value: "15min", req: 'all' },
    { label: "Last hour", value: "1hr", req: 'min' },
    { label: "Last 24 hours", value: "1day", req: 'hr' },
    { label: "By dates", value: "By dates", req: 'date' },
  ];

  const jumpOptions = [
    { label: "Minutes", value: "min", req: 'min' },
    { label: "Hours", value: "hr", req: 'hr' },
    { label: "Days", value: "day", req: 'day' },
  ];

  useEffect(() => {

    setVisible(true);

  }, [sensorsData])

  const setMessageData = (e) => {
    setMessage(e.target.value);
  }

  const addMessage = async () => {
    const res = await addMessageService({ location: selectedFarm?.label, message });
    console.log(res.data)
  }

  const onChangeDeviceHandler = (obj) => {
    dispatch(setSelectedDevice(obj));
  }
  const onChangeTimeHandler = (obj) => {
    dispatch(setSelectedTime(obj))
  }

  const onChangeJumpHandler = (obj) => {
    dispatch(setJumpTime(obj))
  }

  const onChangeDateHandler = (obj) => {

    const _st = { ...selectedTime }
    // console.log(obj)
    dispatch(setSelectedTime({ from_date: obj[0], to_date: obj[1], ..._st }))
  }
  const onChangeFarmHandle = (event) => {
    // console.log(event);
    dispatch(setDevicesBasedOnLocation(event?.label));

    dispatch(setSelectedFarm(event));
  }

  return (
    <div className={styles.Home}>
      <div className={styles.ButtonWrap}>
        <div className={styles.Accordion}>
          <div>
            <OptionsAccordion
              options={
                farms?.map(farm => {
                  return { label: farm, value: farm }
                })
              }
              value={selectedFarm}
              onChange={onChangeFarmHandle}
              text={"Farms"}
            />
            <OptionsAccordion
              options={selectedDevices?.map((item) => ({
                label: <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>{item.deviceId} <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: activeDevices.includes(item.deviceId) ? 'green' : 'red' }} ></div></div>,
                value: item.deviceId,
                item: item
              }))}
              value={selectedDevice}
              onChange={onChangeDeviceHandler}
              text={"Device"}
            />
          </div>
          <div>
            <OptionsAccordion
              options={timeOptions}
              value={selectedTime}
              onChange={onChangeTimeHandler}
              text={"Time Filter"}
            />

            <OptionsAccordion
              options={jumpOptions}
              value={jumpTime}
              onChange={onChangeJumpHandler}
              text={<input style={{ backgroundColor: 'transparent', border: 0, width: "120px", marginRight: '15px', borderBottom: "1px solid black" }} type="number" placeholder={"Enter interval"} onChange={(e) => dispatch(setJump({ time: e.target.value }))} />}
            />
          </div>
          {selectedTime?.label === 'By dates' ? <CustomCalendar value={value} setValue={onChangeDateHandler} selectedDevice={selectedDevice} /> : ""}
        </div>
      </div>
      <Footer />
      {/* {
        graphLoading ?
          <div className={styles.GraphLoader}>
            <Loader.TailSpin
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
          : ""
      } */}
      {/* <button onClick={() => setShowMessage(prevState => !prevState)}>Message</button>
      {showMessage && (
        <div className={styles.MessageBox}>
          <input placeholder="Enter Message" onChange={(e) => setMessageData(e)} />
          <button onClick={() => addMessage()}>Add message</button>
        </div>
      )} */}
      {isVisible && <div className={styles.ChartContainer}>
        <div className={styles.Chart}>
          <ChartWrap
            data={sensorsData?.length > 0 ? sensorsData?.map((item) => ([item?.time, item?.temp])) : []}
            values={sensorsData?.map(item => item?.temp)}
            param="Temperature"
            labels={sensorsData?.map(item => item?.time)}
            units="&deg;C"
          />
        </div>

        <div className={styles.Chart}>
          <ChartWrap
            data={sensorsData?.length > 0 ? sensorsData?.map((item) => ([item?.time, item?.hum])) : []}
            values={sensorsData?.map(item => parseInt(item?.hum))}
            param="Humidity"
            labels={sensorsData?.map(item => item?.time)}
            units="%RH"
          />
        </div>
        <div className={styles.Chart}>
          <ChartWrap
            data={sensorsData?.length > 0 ? sensorsData?.map((item) => ([item?.time, item?.co2])) : []}
            values={sensorsData?.map(item => item?.co2)}
            param="CO2"
            labels={sensorsData?.map(item => item?.time)}
            units="ppm" />
        </div>

        <div className={styles.Chart}>
          <ChartWrap
            data={sensorsData?.length > 0 ? sensorsData?.map((item) => ([item?.time, item?.ammonia])) : []}
            values={sensorsData?.map(item => parseFloat(parseFloat(item?.ammonia) / 17).toFixed(2))}
            param="Ammonia"
            labels={sensorsData?.map(item => item?.time)}
            units="ppm"
          />
        </div>
        <div className={styles.Chart}>
          <ChartWrap
            data={sensorsData?.length > 0 ? sensorsData?.map((item) => ([item?.time, item?.velocity])) : []}
            values={sensorsData?.map(item => item?.velocity)}
            param="Air Velocity"
            labels={sensorsData?.map(item => item?.time)}
            units="m/s"
          />
        </div>
        <div className={styles.Chart}>
          <ChartWrap
            data={sensorsData?.length > 0 ? sensorsData?.map((item) => ([item?.time, item?.dust])) : []}
            values={sensorsData?.map(item => item?.dust)} param="Dust"
            labels={sensorsData?.map(item => item?.time)}
            units="µg/m³" />
        </div>
      </div>
      }
    </div>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
