import { Redirect, Route, useHistory } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Devices from "./pages/Devices/Devices";
import Users from "./pages/Users/Users";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Routes from "./routes/Routes";
import AppWrapper from "./AppWrapper/AppWrapper";
import Data from "./pages/Data/Data";
import useActiveDevices from "hooks/useActiveDevices";
import { setActiveDevices, setDevices } from "store/actions/AppData";

setupIonicReact();

const App: React.FC = () => {
 const { authData } = useSelector((state: any) => state.authData);
 const activeDevicesData = useActiveDevices();

 const dispatch = useDispatch();

 useEffect(() => {
  dispatch(setActiveDevices(activeDevicesData));
 }, [activeDevicesData]);

 return (
  <IonApp>
   {/* <AppWrapper /> */}
   <IonReactRouter>
    <IonRouterOutlet>
     <Routes isAuth={authData ? true : false} data={authData} />
    </IonRouterOutlet>
   </IonReactRouter>
  </IonApp>
 );
};

export default App;
