import React from 'react';
import PropTypes from 'prop-types';
import styles from './Actions.module.scss';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin5Line } from 'react-icons/ri';

const Actions = ({ onEdit, onDelete }) => <div>
  <FaRegEdit
    color="#F85B4D"
    size="1.4rem"
    onClick={onEdit}
    style={{ cursor: "pointer", margin: "0 15px 0 0" }}
  />
  <RiDeleteBin5Line
    color=" #F85B4D"
    size="1.4rem"
    onClick={onDelete}
    style={{ cursor: "pointer" }}
  />

</div>

export default Actions;
