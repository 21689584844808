import { addNewUserUrl, editUserUrl, deleteUserUrl, resendEmailUrl, checkStatusUrl, getDataByIdUrl, verifyStatusUrl } from "services/url/user";

import axios from "services/httpInstance/axios";

export const addNewUser = (data) => {
    return axios.post(addNewUserUrl(data.id), data.data);
}

export const editUser = ({ id, data }) => {
    return axios.put(`${editUserUrl(id)}`, data);
}

export const deleteUser = (id) => {
    return axios.delete(`${deleteUserUrl(id)}`);
}

export const resendEmailService = (email) => {
    return axios.get(resendEmailUrl(email));
}

export const checkStatusService = (email) => {
    return axios.get(checkStatusUrl(email));
}

export const getDataById = (id) => {
    return axios.get(getDataByIdUrl(id));
}

export const verifyStatus = (id) => {
    return axios.get(verifyStatusUrl(id));
}