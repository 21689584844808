import React from "react";
import styles from "./DeleteHandler.module.scss";

const DeleteHandler = ({ setDeleteBtn, title, updateData, deleteFunction, id, message }) => {
  const deleteData = (e) => {
    deleteFunction(id).then((res) => {
      setDeleteBtn(null)
      updateData();
      // console.log(res.status)
    }).catch((err) => {
      console.log(err)
    });
  }
  return (
    <div className={styles.DeleteWrapper}>
      <div className={styles.Wrapper}>
        <div className={styles.Content}>
          <p className={styles.Title}>{title}</p>
          <p className={styles.Desc}>
            {message}
          </p>
        </div>
        <div className={styles.Buttons}>
          <button className={styles.Cancel} onClick={() => setDeleteBtn(false)}>
            Cancel
          </button>
          <button
            onClick={(e) => deleteData(e)}
            className={styles.Delete}>Delete</button>
        </div>
      </div>
    </div>
  );
};


export default DeleteHandler;
