import React from "react";
import { Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../pages/Login/Login";
import DashboardLayout from "Layout/DashboardLayout/DashboardLayout";
import Forgot from "pages/Forgot/Forgot";
import Signup from "pages/SignUp/Signup";
import Reset from "pages/Reset/Reset";
import EmailVerification from "pages/EmailVerification/EmailVerification";

type Props = {
 isAuth: Boolean;
 data: any;
};

const Routes = ({ isAuth, data }: Props) => {
 return (
  <>
   <PublicRoute isAuth={isAuth} exact path="/login">
    <Login />
   </PublicRoute>
   <PublicRoute isAuth={isAuth} exact path="/reset">
    <Forgot />
   </PublicRoute>
   <PublicRoute isAuth={isAuth} exact path="/reset/:id">
    <Reset />
   </PublicRoute>
   <Route exact path="/verify/:id">
    <EmailVerification />
   </Route>
   <PublicRoute isAuth={isAuth} exact path="/signup">
    <Signup />
   </PublicRoute>
   <PrivateRoute isAuth={isAuth} path="/dashboard">
    <DashboardLayout isAdmin={data?.user?.role} />
   </PrivateRoute>
   <PublicRoute isAuth={isAuth} exact path="/">
    <Redirect to="/login" />
   </PublicRoute>
   <PrivateRoute exact isAuth={isAuth} path="/dashboard">
    <Redirect to="/dashboard/home" />
   </PrivateRoute>
  </>
 );
};

export default Routes;
