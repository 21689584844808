import { updateObject } from "helpers/miscellaneous";
import { SET_ACTIVE_DEVICES, SET_DEVICES, SET_SELECTED_DEVICE, SET_SELECTED_TIME, SET_SENSORS_DATA, SET_USERS, SET_SELECTED_DEVICES, SET_FARMS, SET_SELECTED_FARM, SET_JUMP_TIME, SET_JUMP } from "../types/AppData";

const initialState = {
    users: [],
    devices: [],
    selectedDevice: null,
    selectedTime: null,
    sensorsData: [],
    activeDevices: [],
    selectedDevices: [],
    farms: [],
    selectedFarm: [],
    jumpTime: {},
    jump: {}
};

const setSelectedFarm = (state, action) => {
    return updateObject(state, {
        selectedFarm: action.farm
    });
};

const setUsers = (state, action) => {
    return updateObject(state, {
        users: action.users
    });
};

const setJumpTime = (state, action) => {
    return updateObject(state, {
        jumpTime: action.jumpTime
    });
};

const setJump = (state, action) => {
    return updateObject(state, {
        jump: action.jump
    });
};

const setFarms = (state, action) => {
    return updateObject(state, {
        farms: action.farms,
    });
};

const setDevices = (state, action) => {
    const devicesArray = [...action.devices];
    devicesArray.sort((a, b) => String(a.deviceId).localeCompare(String(b.deviceId)));
    return updateObject(state, {
        devices: devicesArray
    });
};

const setDevicesBasedOnLocation = (state, action) => {
    // console.log(state?.devices, action);
    if (action?.location == 'All') {
        return updateObject(state, {
            selectedDevices: state?.devices,
            selectedDevice: { label: state?.devices?.[0]?.deviceId, value: state?.devices?.[0]?.deviceId }
        });;
    } else {
        const _devices = state?.devices?.filter((item) => item?.location == action?.location)
        // console.log(_devices)
        return updateObject(state, {
            selectedDevices: _devices,
            selectedDevice: { label: _devices?.[0]?.deviceId, value: _devices?.[0]?.deviceId }
        });;
    }
}

const setSelectedDevice = (state, action) => {
    return updateObject(state, {
        selectedDevice: action.selectedDevice
    });
};

const setSelectedTime = (state, action) => {
    return updateObject(state, {
        selectedTime: action.selectedTime
    });
};

const setSensorsData = (state, action) => {
    return updateObject(state, {
        sensorsData: action.sensorsData
    });
};

const setActiveDevices = (state, action) => {
    return updateObject(state, {
        activeDevices: action.activeDevices
    });
};




const AppData = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERS:
            return setUsers(state, action);
        case SET_DEVICES:
            return setDevices(state, action);
        case SET_SELECTED_DEVICE:
            return setSelectedDevice(state, action);
        case SET_SELECTED_TIME:
            return setSelectedTime(state, action);
        case SET_SENSORS_DATA:
            return setSensorsData(state, action);
        case SET_ACTIVE_DEVICES:
            return setActiveDevices(state, action);
        case SET_SELECTED_DEVICES:
            return setDevicesBasedOnLocation(state, action);
        case SET_FARMS:
            return setFarms(state, action);
        case SET_SELECTED_FARM:
            return setSelectedFarm(state, action);
        case SET_JUMP_TIME:
            return setJumpTime(state, action);
        case SET_JUMP:
            return setJump(state, action);
        default:
            return state;
    }
};

export default AppData;