import React from 'react'
import styles from './Error.module.scss';

const Error = ({ error }) => {
 console.log(error);
 return (
  <>
   <p className={styles.ErrorWrap}>
    {error.message}
   </p>
  </>
 )
}

export default Error