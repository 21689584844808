import React from 'react';
import styles from './Logout.module.scss';
import { logOut } from 'helpers/auth';
import { useDispatch } from 'react-redux';

const Logout = () => {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(logOut());
  };
  setTimeout(() => {
    dispatch(logout());
  }, 2 * 60 * 1000)
  return (
    <div className={styles.Container}>
      <p className={styles.Heading}>User inactive for more than 15min. Please re-login again</p>
      <button
        onClick={logout}
        className={styles.LogoutBtn}>Log in</button>
    </div>
  )
}

export default Logout