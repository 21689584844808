import React, { useState } from "react";
import { IonPage, IonContent } from "@ionic/react";
import styles from "./Signup.module.scss";
import { Button } from "react-bootstrap";
import { signUp } from "services/api/auth";
import Error from "components/Error/Error";
import Message from "components/Message/Message";
import { useHistory } from "react-router";

const Signup = () => {
  const [error, setError] = useState({ message: "" });
  const [message, setMessage] = useState({ message: "" });
  const [data, setData] = useState({
    username: "",
    name: "",
    email: "",
    password: "",
    confirm: "",
  });
  const history = useHistory();

  const addData = (e) => {
    const copyData = { ...data };
    setError({ message: "" });
    setMessage({ message: "" });
    copyData[e.target.id] = e.target.value;
    setData(copyData);
  };
  const submitData = async (e) => {
    e?.preventDefault();
    if (
      data.email &&
      data.name &&
      data.username &&
      data.password &&
      data.confirm
    ) {
      if (data.password === data.confirm) {
        try {
          const res = await signUp({
            email: data.email,
            username: data.username,
            name: data.name,
            password: data.password,
          });
          if (res.status === 200) {
            setMessage(res.data);
            setTimeout(() => {
              history.replace("/login");
            }, 2000);
          }
        } catch (err) {

          setError({ message: err.response.data.message ? err.response.data.message : err.response.data.message[0].message });
        }
      } else {
        setError({ message: "Password does not match" });
      }
    } else {
      setError({ message: "Please enter all details" });
    }
  };
  return (
    <IonPage>
      <div className={styles.Signup}>
        <div className={styles.Container}>
          <div className={styles.FormWrap}>
            <div className={styles.HeaderWrap}>
              <div className={styles.LogoWrap}>
                <img
                  className={styles.CdacLogo}
                  src="/assets/icon/menu/logo1.png"
                />
                <img
                  className={styles.AgriLogo}
                  src="/assets/icon/menu/logo2.png"
                />
                <img
                  className={styles.DprLogo}
                  src="/assets/icon/menu/logo3.png"
                />
              </div>
              <p className={styles.Title}>Sign Up</p>
            </div>
            <form onSubmit={submitData} className={styles.FormContainer}>
              <div className={styles.FormField}>
                <input
                  className={styles.Form}
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Name"
                  onChange={(e) => {
                    addData(e);
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      submitData(event);
                    }
                  }}
                />
                <input
                  className={styles.Form}
                  id="username"
                  type="text"
                  name="name"
                  placeholder="Username"
                  onChange={(e) => {
                    addData(e);
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      submitData(event);
                    }
                  }}
                />

                <input
                  className={styles.Form}
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={(e) => {
                    addData(e);
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      submitData(event);
                    }
                  }}
                />
                <input
                  className={styles.Form}
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => {
                    addData(e);
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      submitData(event);
                    }
                  }}
                />

                <input
                  className={styles.Form}
                  id="confirm"
                  type="password"
                  name="confirm password"
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    addData(e);
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      submitData(event);
                    }
                  }}
                />
                {/* <input
                  className={styles.Form}
                  id="location"
                  type="text"
                  name="location"
                  placeholder="Location"
                  onChange={(e) => {
                    addData(e);
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      submitData(event);
                    }
                  }}
                /> */}
              </div>
            </form>

            {error.message ? (
              <Error error={error} />
            ) : (
              message.message && <Message message={message} />
            )}
            <div
              style={{ width: "100%", textAlign: "center" }}
              className={styles.Button}
            >
              <button
                type="submit"
                onClick={(e) => {
                  submitData(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Signup;
