import { subscribeToActiveDevices } from "helpers/sockets";
import { useEffect, useState } from "react";

const useActiveDevices = (props) => {
    const [activeDevices, setActiveDevices] = useState([]);
    useEffect(() => {
        subscribeToActiveDevices((err, response) => {
            if (err) return;
            setActiveDevices(response);
        });
    }, []);
    return activeDevices;
};

export default useActiveDevices;
