import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getUserData, resetPass } from "services/api/auth";
import styles from "./Reset.module.scss";
import { IonPage } from "@ionic/react";
import { Button } from "react-bootstrap";

const Reset = () => {
  const [data, setData] = useState({
    password: "",
    confirm: "",
  });
  const [email, setEmail] = useState("");
  const id = useParams()?.id?.split(":")[1].split("+");
  console.log(id);
  const addData = (e) => {
    const copyData = { ...data };
    copyData[e.target.id] = e.target.value;
    setData(copyData);
  };
  const getEmail = async () => {
    const res = await getUserData(id);
    if (res.data.email) setEmail(res.data.email);
  };
  useEffect(() => {
    getEmail();
  }, []);
  const submitData = async (e) => {
    e.preventDefault();
    if (data.password === data.confirm) {
      const res = await resetPass({ ...data, email });
    }
  };
  return (
    // <div>
    //  <input type="password" id="password" placeholder="Enter password" onChange={addData} />
    //  <input type="password" id="confirm" placeholder="Enter confirm password" onChange={addData} />
    //  <button onClick={submitData}>Submit</button>
    // </div>
    <IonPage>
      <div className={styles.Forgot}>
        <div className={styles.Container}>
          <div className={styles.FormWrap}>
            <div className={styles.HeaderWrap}>
              <div className={styles.LogoWrap}>
                <img
                  className={styles.CdacLogo}
                  src="/assets/icon/menu/logo1.png"
                />

                <img
                  className={styles.AgriLogo}
                  src="/assets/icon/menu/logo2.png"
                />
                <img
                  className={styles.DprLogo}
                  src="/assets/icon/menu/logo3.png"
                />
              </div>

              <div className={styles.TextContainer}>
                <div className={styles.Tag}>Please enter your password</div>
              </div>
            </div>

            <form>
              <label>
                <input
                  onChange={addData}
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Enter password"
                />
              </label>
              <label>
                <input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Enter confirm password"
                  onChange={addData}
                />
              </label>
            </form>
            <div className={styles.Button}>
              <Button onClick={(e) => submitData(e)}>Submit</Button>
            </div>
            {/* {error.message ? (
              <Error error={error} />
            ) : (
              message.message && <Message message={message} />
            )} */}
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Reset;
