import { getShorDateFromId } from 'helpers/miscellaneous';
import useAllDates from 'hooks/useAllDates';
import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar';

import { BiCalendarEdit } from 'react-icons/bi';
// import 'react-calendar/dist/Calendar.css';
import './Calendar.css';
import styles from './Calendar.module.scss';
const CustomCalendar = ({ value, setValue, selectedDevice }) => {
    const [clicked, setClicked] = useState(false)
    const [data, setData] = useState(['', '']);
    const [error, setError] = useState({
        message: "",
        value: false,
    })
    const tileDisabled = ({ activeStartDate, date, view }) => {
        const selectedDate = getShorDateFromId(selectedDevice?.item?._id);
        return date < new Date(selectedDate) || date > new Date()
    }
    const onDateChange = (e) => {
        e = e.map((item, index) => (
            index === 1 ?
                new Date(item).getMonth() + 1 + "-" + parseInt(parseInt(new Date(item).getDate()) + 1) :
                new Date(item).getMonth() + 1 + "-" + new Date(item).getDate()) + "-" + new Date(item).getFullYear())
        setValue(e);
        if (e?.[1]) {
            // console.log(e)
            setClicked(false)
        }
        setData(e.map((item, index) => (
            index === 1 ?
                ((getDate(item) - 1) < 10 ? "0" + (getDate(item) - 1) : (getDate(item) - 1)) + "-" + getMonth(item) :
                getDate(item) + "-" + ((parseInt(new Date(item).getMonth())) + parseInt(1))) + "-" + new Date(item).getFullYear()))
    }
    // console.log(data, value)
    const getDate = (date) => {
        // console.log(new Date(date).getDate() <= 10 ? "0" + new Date(date).getDate() : new Date(date).getDate())
        return parseInt(new Date(date).getDate()) <= 10 ? "0" + new Date(date).getDate() : new Date(date).getDate()
    }
    const getMonth = (date) => (new Date(date).getMonth() + 1 <= 10 ? '0' + (new Date(date).getMonth() + parseInt(1)) : new Date(date).getMonth() + 1)
    return (
        <div className={styles.CalendarWrapper}>
            <p className={styles.Heading}>From</p>
            <p className={styles.Date}>{data?.[0] ? data?.[0] : "DD-MM-YYYY"}</p>
            <p className={styles.Heading}>to</p>
            <p className={styles.Date}>{data?.[1] ? data?.[1] : "DD-MM-YYYY"}</p>
            <BiCalendarEdit cursor="pointer" size={20} onClick={() => {
                setClicked(true)
            }} />
            {
                clicked ? <div className={styles.CalendarContainer}>
                    <Calendar
                        className={styles.calendar}
                        tileDisabled={tileDisabled}
                        selectRange={true} onChange={onDateChange} />
                </div> : ""
            }
        </div>
    )
}

export default CustomCalendar