import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import styles from "./Form.module.scss";
import Select from "react-select";
import { MdClose } from "react-icons/md";

const Form = ({ form, setForm, updateData, submitFunction, title, onClickClose, ...rest }) => {

  const [message, setMessage] = useState({ text: '', type: '' });

  const onChangeHandler = (e, i) => {

    let value = e?.target?.value || '';

    if (e.uppercase) {
      value = e?.target?.value.toUpperCase()
    }
    if (form[i].type === 'select') {
      value = e
    }

    let newObject = {
      [i]: {
        ...form[i],
        value: value
      }
    }

    const newForm = Object.assign([...form], newObject);

    setForm(newForm);
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "2px dotted green",
      color: "#000000",
      "&:hover": {
        color: "#F85B4D",
      },
      backgroundColor: "#fff",
      // border: 0,
      // boxShadow: "none",
      cursor: "pointer",
      width: "100%",
    }),

    control: (provided) => ({
      ...provided,
      // border: 0,
      boxShadow: "#F85B4D",
      backgroundColor: "#fff",
      // width: "150px",
      cursor: "pointer",
      marginBottom: '10px'
      // height: "47px"
    }),

    dropdownIndicator: (styles, state) => ({
      ...styles,
      "&:hover": {
        color: "#F85B4D",
        backgroundColor: state.isSelected ? "black" : "",
        borderRadius: "3px",
      },
      minHeight: "5px",
      padding: "0",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#000000",
    }),
  };


  const onClickSubmitHandler = () => {

    let newForm = Object.assign([...form])

    let errors = [];

    newForm = newForm.map(f => {
      if (f.required && !f.value) {
        f.error = true;
        errors.push(true);
      } else {
        f.error = false
        if (Array.isArray(f.value) && f.type !== 'multi-select') {
          console.log(f);
          f.value = f.value.map((g) => {

            g = g.map(h => {
              if (h.required && !h.value) {
                h.error = true;
                errors.push(true);
              }
              else {
                f.error = false
              }
              return h;
            });
            return g;
          })
        }
      }
      return f;
    });

    if (errors.length > 0) {
      // console.log(newForm)
      setForm(newForm);
    } else {
      handleRequest(newForm);
    }

  }

  const handleRequest = async (newForm) => {
    let data = {}
    newForm.forEach((e) => {

      // for dropdown
      if (e.type === 'select') {
        e.value = e.value.value
      }

      if (e.type === 'multi-select') {
        e._value = e.value.map(f => f.value);
      }

      if (Array.isArray(e.value) && e.type !== 'multi-select') {
        let _groupData = []
        e.value.forEach(f => {
          let _fieldData = {}
          f.forEach(g => {
            if (g.value?.value) {
              g._value = g.value.value
            }
            _fieldData = { ..._fieldData, [g.id]: g._value ? g._value : g.value }
          })
          _groupData.push(_fieldData)
        })
        e._value = _groupData
      }
      data = { ...data, [e.id]: e._value ? e._value : e.value }
    });


    // console.log(data);

    setMessage({
      text: '',
      type: 'load'
    })

    submitFunction(rest?.parentId ? { data, id: rest?.parentId } : data).then(r => {

      console.log("ParentID", rest.parentId);

      // console.log(data)

      if (r.status === 200 || 201) {
        setMessage({
          text: r.data.message,
          type: 'success'
        })
        updateData && updateData()

        setTimeout(() => {
          onClickClose();
        }, 1000);
      }
      else {
        setMessage({
          text: 'Something went wrong! Please try again',
          type: 'error'
        })
      }

    }).catch(err => {

      console.log({ err })

      if (err.response) {
        setMessage({
          text: err?.response?.data?.message || 'Something went wrong! Contact Technical team',
          type: 'error'
        })
      }
      else if (err.request) {
        setMessage({
          text: err.request || "Internal server error ",
          type: 'error'
        })
      } else {
        setMessage({
          text: err?.response?.data?.message || 'Something went wrong! Please try again',
          type: 'error'
        })
      }
    })


  }

  useEffect(() => {
    if (!form) {
      setMessage({ text: '', type: '' })
    }

    return () => {
      setMessage({ text: '', type: '' })
    }
  }, [form])


  return (
    <div className={styles.NewUserWrap}>
      <div className={styles.NewUser}>
        <div className={styles.Close} onClick={() => onClickClose()}>
          <MdClose size={24} color={"#F85B4D"} />
        </div>
        <div className={styles.UserWrap}>
          <p className={styles.Title}>
            {title}
          </p>

          <div className={styles.InputWrap}>
            {form.map((item, i) => (
              <div key={i}>
                {item.type === "text" && (
                  <input className={item.error ? styles.Error : ''}
                    type={'text'}
                    value={item.value}
                    placeholder={item?.label}
                    onChange={(e) => {
                      onChangeHandler(e, i)
                    }}
                  />
                )}
                {item.type === "number" && (
                  <input
                    className={item.error ? styles.Error : ''}
                    type={'number'}
                    value={item.value}
                    placeholder={item?.label}
                    onChange={(e) => {
                      onChangeHandler(e, i)
                    }}
                  />
                )}

                {
                  item.type === "select" && (
                    <Select
                      styles={customStyles}
                      options={item.options}
                      value={item.value}
                      onChange={(e) => onChangeHandler(e, i)}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  )
                }
              </div>
            ))}
          </div>
          <div style={{ color: message.type === 'error' ? 'red' : "green" }}>{message.text}</div>
          <Button onClick={(e) => onClickSubmitHandler(e)}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Form;
