import axios from 'axios';
import { getAuthToken } from 'helpers/auth';



// Created Axios instance to use it in whole application
const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        'Content-Type': 'application/json'
    }
});

// declaring interceptors
instance.interceptors.request.use(async response => {
    const token = await getAuthToken();
    // Check if User is authenticated or not
    if (token) {
        response.headers = {
            'Authorization': `${token}`
        };
    }
    return response;
}, error => {
    return error;
});

export default instance