import { IonPage, IonContent } from "@ionic/react";
import React, { useState } from "react";
import styles from "./Login.module.scss";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { loginUser } from "./../../services/api/auth";
import { setItem } from "helpers/miscellaneous";
import { useDispatch } from "react-redux";
import { setAuthData } from "store/actions/AuthData";
import Error from "components/Error/Error";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState({ message: "" });
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const addData = (e) => {
    const copyData = { ...data };
    copyData[e.target.id] = e.target.value;
    setData(copyData);
    setError()
  };
  const submitData = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(data);
      if (response.status === 200) {
        await setItem("auth", response.data);
        await setItem("time", new Date());
        dispatch(setAuthData(response.data));
        history.replace("/dashboard/home");
      }
    } catch (error) {
      if (error.response.status === 401) {
        // console.log(error.response.data);
        setError(error.response.data);
      }
    }
  };
  return (
    <IonPage>
      <IonContent>
        <div className={styles.Login}>
          <div className={styles.Container}>
            <div className={styles.FormWrap}>
              <div className={styles.HeaderWrap}>
                <div className={styles.LogoWrap}>
                  <img
                    className={styles.CdacLogo}
                    src="/assets/icon/menu/logo1.png"
                    alt=""
                  />

                  <img
                    className={styles.AgriLogo}
                    src="/assets/icon/menu/logo2.png"
                    alt=""
                  />
                  <img
                    className={styles.DprLogo}
                    src="/assets/icon/menu/logo3.png"
                    alt=""
                  />
                </div>

                <p className={styles.Title}>Sign in</p>
              </div>

              <form>
                <label>
                  <input
                    id="username"
                    type="text"
                    name="name"
                    placeholder="Enter username"
                    onChange={(e) => {
                      addData(e);
                    }}
                    onKeyDown={(event) => {
                      if (event.key == "Enter") {
                        submitData(event);
                      }
                    }}
                  />
                </label>
                <label>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    onChange={(e) => {
                      addData(e);
                    }}
                    onKeyDown={(event) => {
                      if (event.key == "Enter") {
                        submitData(event);
                      }
                    }}
                  />
                </label>
              </form>
              <div className={styles.Button}>
                <button onClick={submitData}>
                  Sign in
                </button>
                <div className={styles.Navigate}>
                  <p className={styles.SignUp} onClick={(e) => history.push("/signup")}>Not a member?</p>
                  <p onClick={(e) => history.push("/reset")} className={styles.Password}>Forgot password?</p>
                </div>
              </div>
              {error?.message && <Error error={error} />}
            </div>

          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
