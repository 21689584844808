import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getDataById, verifyStatus } from "services/api/user";
import styles from "./EmailVerification.module.scss";
import { Button } from "react-bootstrap";
import { IonPage } from "@ionic/react";

const EmailVerification = () => {
 const params = useParams();
 const _params = params?.id?.split(":")[1];
 const id = _params.split("+")[0];
 const time = _params.split("+")[1];
 const timeElapsed = (Date.now() - time) / 60000;
 const [user, setUser] = useState({});
 const [message, setMessage] = useState({});
 const getData = async (id) => {
  try {
   const res = await getDataById(id);
   if (res.status === 200) {
    console.log(res.data);
    setUser(res.data);
   }
  } catch (err) {
   console.log(err.details);
  }
 }
 const VerifyStatus = async (e) => {
  e.preventDefault();
  try {
   const res = await verifyStatus(id);
   setMessage(res.data);
  } catch (err) {
   console.log(err);
  }
 }
 useEffect(() => {
  if (id) {
   getData(id);
  }
 }, [id])
 return (
  <IonPage>
   <div className={styles.Forgot}>
    <div className={styles.Container}>
     <div className={styles.FormWrap}>
      {user?.verified ? <p>user is already verified</p> : timeElapsed > 5 ? <p>Time exceeded more than 5 min</p> : <>
       <div className={styles.HeaderWrap}>
        <div className={styles.LogoWrap}>
         <img
          className={styles.CdacLogo}
          src="/assets/icon/menu/logo1.png"
         />
         <img
          className={styles.AgriLogo}
          src="/assets/icon/menu/logo2.png"
         />
         <img
          className={styles.DprLogo}
          src="/assets/icon/menu/logo3.png"
         />
        </div>

        <div className={styles.TextContainer}>
         <div className={styles.Tag}>
          Hello {user?.name}! Click on the below button to verify your email
         </div>
        </div>
       </div>
       <div className={styles.Button}>
        {message?.message ? message.message : <Button onClick={(e) => VerifyStatus(e)}>Verify</Button>}
       </div>
      </>}
     </div>
    </div>
   </div>
  </IonPage>
 );
};

export default EmailVerification;
