import { getUserDataUrl, loginUrl, resetPassUrl, resetUrl, signUpUrl } from "services/url/auth";

import axios from "services/httpInstance/axios";

export const loginUser = (data) => {
    return axios.post(loginUrl(), data);
}

export const resetPassword = (data) => {
    return axios.post(resetUrl(), data);
}

export const getUserData = (data) => {
    return axios.get(getUserDataUrl() + data);
}

export const resetPass = (data) => {
    return axios.post(resetPassUrl(), data);
}

export const signUp = (data) => {
    return axios.post(signUpUrl(), data);
}