import { subscribeToSensorData } from "helpers/sockets";
import { useEffect, useState } from "react";

const useSensorData = (props) => {
    const [sensorData, setSensorData] = useState(null);
    useEffect(() => {
        subscribeToSensorData((err, response) => {
            if (err) return;
            setSensorData(response);
        });
    }, []);
    return sensorData;
};

export default useSensorData;
