import React, { useEffect, useState } from 'react';

const useInactiveTime = (props) => {
 const [isLogout, setLogout] = useState(false);
 const [_timer, setTimer] = useState();
 let timer = undefined;
 const time = 10 * 60 * 1000;
 const events = ['click', 'load', 'keydown']
 const eventHandler = (eventType) => {
  if (!isLogout) {
   localStorage.setItem('lastInteractionTime', Date.now())
   if (timer) {
    startTimer();
   }
  }

 };

 useEffect(() => {
  CheckOnLoad();
  addEvents();
  return (() => {
   removeEvents();
  })
 }, [])

 const CheckOnLoad = () => {
  const lastInteractionTime = localStorage.getItem('lastInteractionTime')
  const diff = Date.now() - lastInteractionTime;
  if (diff > time) {
   console.log("Logged out!");
  }
 }

 const startTimer = () => {

  if (timer) {
   clearTimeout(timer)
  }
  timer = setTimeout(() => {

   let lastInteractionTime = localStorage.getItem('lastInteractionTime')
   const diff = Date.now() - lastInteractionTime;
   let timeOutInterval = time;
   if (isLogout) {
    clearTimeout(timer)
   } else {
    if (diff._milliseconds < timeOutInterval) {
     startTimer();
    } else {
     setTimer(timer);
    }
   }
  }, time)
 }
 const addEvents = () => {

  events.forEach(eventName => {
   window.addEventListener(eventName, eventHandler)
  })

  startTimer();
 }

 const removeEvents = () => {
  events.forEach(eventName => {
   window.removeEventListener(eventName, eventHandler)
  })
 };

 return _timer;

}

export default useInactiveTime;