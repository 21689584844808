import React from 'react';
import PropTypes from 'prop-types';
import styles from './ItemsList.module.scss';
import ItemDetails from 'components/ItemDetails/ItemDetails';

const ItemsList = ({ items, onEdit, onDelete }) => (
  <div className={styles.ItemsList}>
    {
      items.map((item, index) => {
        return (
          <ItemDetails
            num={index + 1}
            data={item}
            onEdit={() => onEdit(index)}
            onDelete={() => onDelete(index)}
          />
        );
      })
    }
  </div>
);

ItemsList.propTypes = {};

ItemsList.defaultProps = {};

export default ItemsList;
