import React from "react";
import styles from "./Common.module.scss";
import Menu from "./Menu/Menu";
import Header from "./Header/Header";
import { IonContent, IonPage } from "@ionic/react";

const Common = ({ heading, children, menu }) => (
  <IonPage className={styles.Common}>
    <IonContent>
      <div className={styles.Container}>
        <div className={styles.SideMenu}>
          <Menu data={menu} />
        </div>
        <div className={styles.MainContent}>
          <Header heading={heading} />
          <div className={styles.Content}>{children}</div>
        </div>
      </div>
    </IonContent>
  </IonPage>
);

Common.propTypes = {};

Common.defaultProps = {};

export default Common;

