import React from 'react';
import styles from './NotVerified.module.scss'
import { checkStatusService, resendEmailService } from 'services/api/user';
import { useDispatch } from 'react-redux';
import { changeVerificationStatus, setAuthData } from 'store/actions/AuthData';

const NotVerified = ({ email }) => {
  const dispatch = useDispatch();
  const resendEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await resendEmailService(email);
      if (response.status === 200) {
        console.log(response.data);
      }
    } catch (err) {
      console.log(err.response.data);
    }
  }
  const checkStatus = async (e) => {
    e.preventDefault();
    try {
      const response = await checkStatusService(email);
      if (response.status === 200) {
        if (response.data.orgId)
          dispatch(setAuthData({ token: response.data.token, user: response.data.user, orgId: response.data.orgId }))
        dispatch(changeVerificationStatus(response.data));
      }
    } catch (err) {
      console.log(err.response.data);
    }
  }
  return (
    <div className={styles.Container}>
      <p className={styles.Heading}>Email not verified! Please verify your email to proceed.</p>
      <div className={styles.ButtonsContainer}>
        <button
          onClick={resendEmail}
          className={styles.ResendBtn}>Resend email</button>
        <button
          onClick={checkStatus}
          className={styles.CheckBtn}>Check status</button>
      </div>
    </div>
  )
}

export default NotVerified