import React from 'react';
import styles from './Message.module.scss'

const Message = ({ message }) => {
 console.log(message);
 return (
  <div>
   <p className={styles.MessageWrap}>{message.message}</p>
  </div>
 )
}

export default Message