import React, { useEffect, useState } from "react";
import styles from "./Data.module.scss";
import Common from "../../components/Common/Common";
import Button from "../../components/Button/Button";
import OptionsAccordion from "../../components/OptionsAccordion/OptionsAccordion";
import Footer from "../../components/Footer/Footer";
import Row from "../../components/Row/Row";
import { IonGrid } from "@ionic/react";
import Pages from "../../components/NavMenu/CurserMenu/Pages";
import useWindowSize from "hooks/useWindowSize";
import UserDetails from "components/ItemDetails/ItemDetails";
import Select from "react-select";
import { useSelector } from "react-redux";
import { downloadData } from "services/api/devices";
import * as XLSX from 'xlsx';
import { useDispatch } from "react-redux";
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import useAllDates from "hooks/useAllDates";
import CustomCalendar from "components/Calendar/Calendar";
import { getShorDateFromId } from "helpers/miscellaneous";
import { Bars, RotatingLines } from "react-loader-spinner";
import { requestDownloadData } from "helpers/sockets";
import { setJumpTime, setJump } from "store/actions/AppData";
import useDownloadData from "hooks/useDownloadData";
const Data = () => {

  const { devices, jump, jumpTime } = useSelector(state => state.appData)
  const [clicked, setClicked] = useState(false)
  const [selectedTime, setSelectedTime] = useState();
  const [selectedDevice, setSelectedDevice] = useState();
  const [error, setError] = useState({
    message: "",
    value: false,
  })
  const onChangeJumpHandler = (obj) => {
    dispatch(setJumpTime(obj))
  }
  const onChangeDateHandler = (obj) => {

    const _st = { ...selectedTime }
    console.log(obj)
  }
  const dispatch = useDispatch();
  const [value, setValue] = useState('')
  const onChangeHandler = async (e, type) => {
    if (type === 'device')
      setSelectedDevice(e);
    if (type === 'time')
      setSelectedTime(e);
  };
  const jumpOptions = [
    { label: "Minutes", value: "min", req: 'min' },
    { label: "Hours", value: "hr", req: 'hr' },
    { label: "Days", value: "day", req: 'day' },
  ];

  const res = useDownloadData();
  useEffect(() => {
    if (res) {
      //adjust the time in IST format 20-8-2024-ranjita
      const adjustData=res.map(item=> {
        const adjustItem={...item};
        if(adjustItem['Time']){
          const originalDate=new Date(adjustItem['Time']);
          const istDate=new Date(originalDate.getTime()+5.5*60*60*1000);
          adjustItem['Time']=istDate.toISOString().split('.')[0].replace('T', ' ');
        }
        return adjustItem;
      });
      //end 20-8-2024-ranjita
      const worksheet = XLSX.utils.json_to_sheet(res);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "CDAC-" + selectedDevice?.location + "-" + selectedDevice.value + ".xlsx");
      setClicked(false);
    } else {
      console.log("NO DATA")
      setClicked(false)
    }
  }, [res])
  const submitData = async (e) => {
    setClicked(true)
    console.log(selectedDevice, value)
    if (!selectedDevice || !value[0] || !value[1]) {

      setError({
        message: "Please select a device and dates",
        value: true,
      });

      setTimeout(() => {
        setError({
          message: "",
          value: false,
        });
      }, 2000);
      setClicked(false)
      return;
    }

    try {
      // console.log(selectedDevice)
      requestDownloadData({ deviceId: selectedDevice.value, from_time: new Date(value?.[0]).valueOf() || '', to_time: new Date(value?.[1]).valueOf() || '', by: "download", jump, jumpTime })
      // console.log(res.data.length > 0);
    }
    catch (err) {
      console.log(err);
      setClicked(false)
    }
  }

  return (
    <>

      <div className={styles.MainContainer}>
        <div className={styles.FormWrap}>
          <div className={styles.DropDown}>
            <OptionsAccordion
              options={devices?.map((item) => ({
                label: item.deviceId,
                value: item.deviceId,
                date: getShorDateFromId(item?._id),
                location: item.location,
                item: item
              }))}
              onChange={(e) => onChangeHandler(e, 'device')}
              text={"Device id"} />

            <CustomCalendar selectedDevice={selectedDevice} setValue={setValue} value={value} />
            <OptionsAccordion
              options={jumpOptions}
              value={jumpTime}
              onChange={onChangeJumpHandler}
              text={<input style={{ backgroundColor: 'transparent', border: 0, width: "100px", borderBottom: "1px solid black" }} type="number" placeholder={"Enter time"} onChange={(e) => dispatch(setJump({ time: e.target.value }))} />}
            />
          </div>
          <div className={styles.Btn}>
            <button onClick={(e) => submitData(e)}>Export</button>
          </div>
          {
            error.message && error.value ? <p>{error.message}</p> : ""
          }
          <div className={styles.Note}>
            Note: The data exporting time will be higher when there is a lot of data.
          </div>
        </div>
        {
          clicked &&
          <div className={styles.Loader}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />

          </div>
        }
      </div>
    </>
  );
};

Data.propTypes = {};

Data.defaultProps = {};

export default Data;
