import React, { useState, useEffect } from "react";
import styles from "./Users.module.scss";
import Button from "../../components/Button/Button";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useWindowSize from "hooks/useWindowSize";
import { getAllUsers } from "services/api/allUsers";
import { setUsers } from "store/actions/AppData";
import { getFormFieldsWithValue } from "helpers/FormEdit";
import { UserFields } from "helpers/FormFields";
import Actions from "components/Table/Actions/Actions";
import Form from "components/Forms/Form/Form";
import DeleteHandler from "components/Forms/DeleteHandler/DeleteHandler";
import ItemsList from "components/ItemsList/ItemsList";
import Table from "components/Table/Table";
import { addNewUser, deleteUser, editUser } from "services/api/user";

const Users = () => {
  const { users } = useSelector((state) => state.appData);
  const { orgId, user } = useSelector((state) => state.authData.authData);
  const userRole = user?.role;
  const [form, setForm] = useState(null);
  const { width } = useWindowSize();
  const [editItemId, setEditItemId] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const dispatch = useDispatch();

  const updateData = () => {
    getAllUsers(orgId)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setUsers(res.data));
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // setError(error.response.data);
        }
        if (error.response.status === 402) {
          // console.log(error.response.data);
          // setError(error.response.data);
        }
      });
  };

  useEffect(() => {
    updateData()
  }, [])

  const onClickEditHandler = (i) => {
    setForm(getFormFieldsWithValue(UserFields(), users[i]));
    setEditItemId(users[i]?._id)
  }
  const onClickDeleteHandler = (i) => {
    setDeleteItemId(users[i]?._id)
  }

  const headerData = userRole > 0 ? ["SR. NO.", "Name", "Email", "Username", "User Type", "Location", "Actions"] : ["SR. NO.", "Name", "Email", "Username", "User Type", "Location"];

  let _users = users.map(({ name, email, username, role, location }, index) => {
    const actions = userRole > 0 ? { actions: <Actions onEdit={() => onClickEditHandler(index)} onDelete={() => onClickDeleteHandler(index)} /> } : null;
    return (
      {
        srno: index + 1, name, email,
        username, "User Type": role == 0 ? 'User' : role == 1 ? 'Admin' : 'Super Admin',
        location,
        ...actions
      }
    )
  })

  let _usersDetails = users.map(({ name, email, username, role, location }, index) => ({
    "Name": name, "Email": email,
    "Username": username, "User Type": role == 0 ? 'User' : role == 1 ? 'Admin' : 'Super Admin', "Location": location
  }))

  const onClickAddhandler = () => {
    setForm(UserFields());

    // console.log(form)
  }

  useEffect(() => {
    // console.log("form")
    // console.log({ form })
  }, [form]);





  return (

    <>
      {form && <Form form={form} setForm={setForm} updateData={updateData} submitFunction={editItemId ? editUser : addNewUser} title={editItemId ? 'Edit User' : "Add New user"} onClickClose={() => {
        setForm(null);
        setEditItemId(null);
      }} parentId={editItemId ? editItemId + `+${orgId}` : orgId} />}

      {deleteItemId && (
        <DeleteHandler
          message={` Deleting this user will delete all of its data`}
          setDeleteBtn={() => setDeleteItemId(null)}
          title="Delete user"
          updateData={updateData}
          deleteFunction={deleteUser}
          id={deleteItemId + `+${orgId}`}
        />
      )}

      <div className={styles.Devices}>
        <div className={styles.ButtonWrap}>
          <div className={styles.Accordion}>
            {/* <OptionsAccordion
              options={users?.map((item) => ({
                label: item.deviceId,
                value: item.deviceId,
              }))}
              text={"Device"}
            />
            <OptionsAccordion text={"Status"} /> */}
          </div>
          <Button className={styles.AddBtn} text="Add user" type="device" onClick={() => onClickAddhandler()} width={width} />
        </div>
        {width < 768
          ? <ItemsList items={_usersDetails} onDelete={onClickDeleteHandler} onEdit={onClickEditHandler} />
          : <Table headerData={headerData} bodyData={_users} />}

        {/* <Footer /> */}

      </div>
    </>
  );
};


export default Users;
