import React, { useState } from "react";
import { IonPage, IonContent } from "@ionic/react";
import styles from "./Forgot.module.scss";
import { Button } from "react-bootstrap";
import { resetPassword } from "services/api/auth";
import Error from "components/Error/Error";
import Message from "components/Message/Message";
import { useHistory } from "react-router";

const Forgot = () => {
  const [error, setError] = useState({ message: "" });
  const [message, setMessage] = useState({ message: "" });
  const history = useHistory();
  const [data, setData] = useState({
    name: "",
    email: "",
  });
  const addData = (e) => {
    const copyData = { ...data };
    setError({ message: "" });
    setMessage({ message: "" });
    copyData[e.target.id] = e.target.value;
    setData(copyData);
  };

  const submitData = async (e) => {
    e.preventDefault();
    if (data.email && data.name) {
      try {
        const res = await resetPassword(data);
        if (res.status === 200) {
          setMessage(res.data);
          setTimeout(() => {
            history.replace("/login");
          }, 2000);
        }
      } catch (err) {
        console.log(err.response);
        setError(err.response.data);
      }
    } else {
      setError({ message: "Please enter all details" });
    }
  };

  return (
    <IonPage>
      <div className={styles.Forgot}>
        <div className={styles.Container}>
          <div className={styles.FormWrap}>
            <div className={styles.HeaderWrap}>
              <div className={styles.LogoWrap}>
                <img
                  className={styles.CdacLogo}
                  src="/assets/icon/menu/logo1.png"
                />

                <img
                  className={styles.AgriLogo}
                  src="/assets/icon/menu/logo2.png"
                />
                <img
                  className={styles.DprLogo}
                  src="/assets/icon/menu/logo3.png"
                />
              </div>

              <div className={styles.TextContainer}>
                <p className={styles.Title}>Forgot Password?</p>
                <p className={styles.Tag}>
                  Please enter your registered email address we will get back to
                  you with the reset password link
                </p>
              </div>
            </div>

            <form>
              <label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  onChange={(e) => {
                    addData(e);
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      submitData(event);
                    }
                  }}
                />
              </label>
              <label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email ID"
                  onChange={(e) => {
                    addData(e);
                  }}
                  onKeyDown={(event) => {
                    if (event.key == "Enter") {
                      submitData(event);
                    }
                  }}
                />
              </label>
            </form>
            <div className={styles.Button}>
              <Button onClick={submitData}>Submit</Button>
            </div>
            {error.message ? (
              <Error error={error} />
            ) : (
              message.message && <Message message={message} />
            )}
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Forgot;
